<template>
    <div class="underConstruction">
        <!-- <Navbar color="white"/> -->
        <v-img
            src="@/assets/construction.jpg"
            height="100vh"
        >   
            <div class="d-flex justify-center align-center flex-column" id="link">
                <h2
                    class="white--text text-h4 text-md-h2 px-3 text-center"
                >
                    Sitio en Construcción
                </h2>
                <a href="http://mapisapty.com" class="pt-5">
                    <v-btn
                        color="blue"
                        tile
                        class="white--text"
                        :small="$vuetify.breakpoint.smAndDown"
                    >
                        Regresar al inicio
                    </v-btn>
                </a>
            </div>
        </v-img>
        <!-- <FooterComponent /> -->
    </div>
</template>

<script>
// import Navbar from "@/components/home_components/Navbar"
// import FooterComponent from  "@/components/Tienda/Footer.vue"
export default {
    name: "UnderConstruction",
    components : {}

}
</script>

<style scoped>
    #link{
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
    }
</style>